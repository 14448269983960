<template>
    <tr>
        <td>{{ name }} :</td>
        <td> 
            <v-select
                @option:selected="onchange"
                class="width-300"
                v-model="selected"
                :options="options" 
                label="name"
            />
        </td>
    </tr>
</template>

<script>
import handleInventory from '@/services/modules/inventory'
export default {
    name : 'BrandValueSelector',

    data: () => ({
        selected: null,
        options: []
    }),

    methods: {
        async getOptions() {
            let res = await this.fetchSpecificationValue(this.name, this.$route.params.companyId)
            this.options = res.data
        },

        onchange (event) {
            this.setBrandValue(this.name, event.id)
        }
    },

    mounted() {
        this.getOptions();
    },

    props: {
        name: String,
        setBrandValue: Function
    },

    setup() {
        const {
            fetchSpecificationValue
        } = handleInventory()

        return {
            fetchSpecificationValue
        }
    },
}
</script>
