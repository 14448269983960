<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Add Alternative Unit</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">

                <div class="pb-2">
                    <!-- form -->
                    <vForm @submit="submit" :validation-schema="schema">
                        <vField v-model="formData.base_unit_id" class="d-none" name="base_unit_id"/>
                        <div class="row gy-2 px-2">
                            <p class="font-weight-bold mb-0">Base Unit: {{baseUnit.name ?? 'N/A'}}</p>
                            <div class="col-12">
                                <label class="form-label" for="name">Name</label>
                                <vField
                                    v-model="formData.name"
                                    name="name"
                                    type="text"
                                    id="name"
                                    class="form-control add-credit-card-mask"
                                    placeholder="Name"
                                />
                                <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="key">Abbreviation</label>
                                <vField
                                    v-model="formData.key"
                                    name="key"
                                    type="text"
                                    id="key"
                                    class="form-control add-credit-card-mask"
                                    placeholder="Abbreviation"
                                />
                                <ErrorMessage name="key" class="invalid-feedback d-block qcont"/>
                            </div>
                            <div class="col-12">
                                <label class="form-label" for="conversion_rate">Conversion rate</label>
                                <vField
                                    v-model="formData.conversion_rate"
                                    name="conversion_rate"
                                    type="number"
                                    id="conversion_rate"
                                    class="form-control add-credit-card-mask"
                                    placeholder="Conversion rate"
                                />
                                <ErrorMessage name="conversion_rate" class="invalid-feedback d-block qcont"/>
                            </div>
                        </div>

                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button :disabled="loading" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <slot v-if="!formData.id">Submit</slot>
                                <slot v-else>Update</slot>
                            </button>
                            <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, inject, ref, watch} from "vue";
import handleInventory from "@/services/modules/inventory";
import {useRoute} from "vue-router";
const props = defineProps({
    selectedUnitId: Number,
    unitList: Array,
    editUnit: Object|null
})
const {
    addAlternativeUnits,
    updateAlternativeUnits
} = handleInventory()

const $emit = defineEmits(['unitModalClose'])

const $route = useRoute();
const isOpen = ref(false)
const loading = ref(false)
const showError = inject('showError')
const showSuccess = inject('showSuccess')
const schema = {
    name: 'required|min:1|max:150',
    key: 'required|max:150',
    conversion_rate: 'required'
}

const baseUnit = computed(() => props.unitList.find(unit => unit.id === props.selectedUnitId))

const formData = ref({
    company_id: $route.params.companyId,
    base_unit_id: null,
    name: null,
    key: null,
    conversion_rate: null,
})

const clearFormData = () => {
    formData.value = {
        company_id: $route.params.companyId,
        base_unit_id: null,
        name: null,
        key: null,
        conversion_rate: null,
    }
}

const editUnit = computed(() => props.editUnit)

watch(editUnit, () => {
    formData.value = editUnit.value;
})

//methods
const toggleModal = () => {
    isOpen.value = !isOpen.value
    clearFormData()
}
const submit = () => {
    formData.value.base_unit_id = props.selectedUnitId;
    loading.value = true;
    if(formData.value.id) {
        updateAlternativeUnits(formData.value.id, formData.value)
        .then(res => {
            if (res.status) {
                showSuccess(res.message)
                $emit('unitModalClose', res.data)
            }
        })
            .catch( err => {
                showError(err.response.message);
            })
            .finally(() => {
                loading.value = false
                toggleModal()
            })
        return;
    }
    addAlternativeUnits(formData.value).then(res => {
        if (res.status) {
            showSuccess(res.message)
            $emit('unitModalClose', res.data)
        }
    })
    .catch( err => {
        showError(err.response.message);
    })
    .finally(() => {
        loading.value = false
        toggleModal()
    })
}

defineExpose({toggleModal})
</script>

<style scoped>
    .font-weight-bold{
        font-weight: bold;
    }
</style>