<template>
    <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered modal-custom">
            <div class="modal-content">
                <div class="row">
                    
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">Edit Product</h1>
                            <button
                                @click="toggleModal"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">
                
                <div class="pb-2">
                    <!-- form -->
                    <vForm @submit="submit" :validation-schema="schema">
                        <vField v-model="formData.product_head_id" class="d-none" name="product_head_id"/>
                        <div class="row gy-2 px-2">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-12 px-2">
                                        <label class="form-label" for="select2-basic">Product & Service Group</label>
                                        <v-select
                                            v-model="formData.product_head_id"
                                            :options="group"
                                            label="name"
                                            :reduce="name => name.id"
                                        />
                                        <ErrorMessage name="product_head_id" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-12">
                                        <label class="form-label" for="name">Name</label>
                                        <vField
                                            v-model="formData.name"
                                            name="name"
                                            type="text"
                                            id="name"
                                            class="form-control add-credit-card-mask"
                                            placeholder="Name"
                                        />
                                        <ErrorMessage name="name" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div v-if="formData.image" class="col-12">
                                        <div class="d-flex">
                                            <BlobImageViewer
                                                :file="formData.image"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div v-if="!formData.image">
                                            <img
                                                class="p-1"
                                                :src="productSingleOriginal.description?.image"
                                                width="100" alt="not selected"
                                            >
                                        </div>
                                        <label for="formFile" class="form-label">Select Primary Image</label>
                                        <input @change="onUploadPrimaryPhoto" class="form-control" type="file" id="formFile">
                                    </div>
                                    <div class="col-12 text-center">
                                        <div v-if="productSingleOriginal.images?.length && !formData.additional_images?.length" class="flex flex-wrap">
                                            <img
                                                v-for="image in productSingleOriginal.images"
                                                class="p-1"
                                                :key="image.id"
                                                :src="image.path"
                                                width="100"
                                                alt=""
                                            >
                                        </div>
                                        <div class="d-flex flex-wrap justify-content-between">
                                            <BlobImageViewer
                                                v-for="(file, index) in formData.additional_images"
                                                :file="file"
                                                :key="index"
                                                :index="index"
                                            >
                                                <template v-slot:handler>
                                                    <img
                                                        @click="removeImage(index)"
                                                        class="position-absolute cursor-pointer top-0"
                                                        width="15"
                                                        src="/app-assets/images/icons/trash.svg"
                                                        alt=""
                                                    >
                                                </template>
                                            </BlobImageViewer>
                                        </div>
                                        <div>
                                            <div v-bind="getRootProps()">
                                                <input v-bind="getInputProps()" >
                                                <p v-if="isDragActive">Drop the files here ...</p>
                                                <p v-else>Drag 'n' drop some additional images</p>
                                            </div>
                                            <button @click.prevent="open">open</button>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <vField v-model="formData.unit_id" class="d-none" name="unit_id"/>
                                        <label class="form-label" for="select2-basic">Base Unit</label>
                                        <v-select
                                            v-model="formData.unit_id"
                                            :options="units"
                                            label="name"
                                            :reduce="name => name.id"
                                        />
                                        <ErrorMessage name="unit_id" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-12 my-2" v-if="formData.unit_id">
                                        <vField v-model="formData.alternative_unit_ids" class="d-none" name="alternative_unit_ids"/>
                                        <label
                                            class="form-label d-flex justify-content-between"
                                            for="select2-basic"
                                        >
                                            Alternative Units
                                            <a
                                                href="#"
                                                class="btn btn-primary btn-sm"
                                                @click="toggleAlternativeUnitModal"
                                            >
                                                Add new
                                            </a>
                                        </label>
                                        <v-select
                                            v-model="selectedAlternativeUnit"
                                            :options="alternativeUnits"
                                            label="name"
                                            :reduce="name => name.id"
                                            @option:selected="selectAlternativeUnit"
                                        />
                                        <ErrorMessage name="alternative_unit_ids" class="invalid-feedback d-block qcont"/>
                                        <div class="table-responsive">
                                            <table
                                                class="table table-bordered mt-2"
                                                v-if="alternativeUnitsData.length > 0"
                                            >
                                                <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Abbr</th>
                                                    <th>CR</th>
                                                    <th>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr
                                                    v-for="(unit, index) in alternativeUnitsData"
                                                    :key="index"
                                                    class="text-center"
                                                >
                                                    <td>{{unit.name}}</td>
                                                    <td>{{unit.key}}</td>
                                                    <td>{{unit.conversion_rate}}</td>
                                                    <td class="d-flex justify-content-center gap-2">
                                                        <button
                                                            class="btn btn-sm btn-primary"
                                                            @click="(e) => triggerEdit(e, unit.id)"
                                                        >
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                        <button
                                                            class="btn btn-sm btn-danger"
                                                            @click="(e) =>  deleteSelectedUnit(e, unit.id)"
                                                        >
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="selectedAlternativeUnits.length > 0">
                                        <vField v-model="formData.default_purchase_unit_id" class="d-none" name="unit_id"/>
                                        <label class="form-label" for="select2-basic">Purchase Unit</label>
                                        <v-select
                                            v-model="formData.default_purchase_unit_id"
                                            :options="selectedAlternativeUnits"
                                            label="name"
                                            :reduce="name => name.id"
                                        />
                                        <ErrorMessage name="default_purchase_unit_id" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-md-12" v-if="selectedAlternativeUnits.length > 0">
                                        <vField v-model="formData.default_sales_unit_id" class="d-none" name="unit_id"/>
                                        <label class="form-label" for="select2-basic">Sales Unit</label>
                                        <v-select
                                            v-model="formData.default_sales_unit_id"
                                            :options="selectedAlternativeUnits"
                                            label="name"
                                            :reduce="name => name.id"
                                        />
                                        <ErrorMessage name="default_sales_unit_id" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-md-12" v-if="selectedAlternativeUnits.length > 0">
                                        <vField v-model="formData.default_shipping_unit_id" class="d-none" name="unit_id"/>
                                        <label class="form-label" for="select2-basic">Shipping Unit</label>
                                        <v-select
                                            v-model="formData.default_shipping_unit_id"
                                            :options="selectedAlternativeUnits"
                                            label="name"
                                            :reduce="name => name.id"
                                        />
                                        <ErrorMessage name="default_shipping_unit_id" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label" for="select2-basic">Add Specifications</label>
                                        <v-select
                                            @option:deselecting="onDeselectSpec"
                                            multiple
                                            v-model="selectedSpecifications"
                                            :options="specificationArr"
                                            label="name"
                                        />
                                    </div>
                                    <div class="col-md-12">
                                        <table>
                                            <BrandValue
                                                v-for="(name, i) in selectedSpecifications"
                                                :setBrandValue="setBrandValue"
                                                :key="i"
                                                :name="name"
                                            />
                                        </table>
                                    </div>
                                    <div class="col-md-12">

                                <span
                                    v-for="sp in productSingleOriginal.specifications"
                                    :key="sp.id"
                                    class="badge rounded-pill badge-light-secondary"
                                >
                                    {{sp.name}}
                                </span>
                                    </div>
                                    <div class="col-12">
                                        <label class="form-label" for="name">Short Description</label>
                                        <vField
                                            as="textarea"
                                            v-model="formData.short_description"
                                            name="short_description"
                                            id="Short"
                                            class="form-control add-credit-card-mask"
                                            placeholder="Short"
                                        />
                                        <ErrorMessage name="short_description" class="invalid-feedback d-block qcont"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="form-label" for="Full">Full Description</label>
                                        <vField
                                            as="textarea"
                                            v-model="formData.full_description"
                                            name="full_description"
                                            id="Full"
                                            class="form-control add-credit-card-mask"
                                            placeholder="Full"
                                        />
                                        <ErrorMessage name="full_description" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-12">
                                        <label class="form-label" for="purchase_price">Purchase Price</label>
                                        <vField
                                            type="text"
                                            v-model="formData.purchase_price"
                                            name="purchase_price"
                                            id="purchase_price"
                                            class="form-control add-credit-card-mask text-end"
                                            placeholder="Purchase price"
                                        />
                                        <ErrorMessage name="purchase_price" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label" for="select2-basic">Purchase Account</label>
                                        <v-select
                                            v-model="formData.purchase_account_head_id"
                                            :options="accountHeads"
                                            label="name"
                                            :reduce="name => name.id"
                                        />
                                    </div>

                                    <div class="col-12">
                                        <label class="form-label" for="sales_price">Sales Price</label>
                                        <vField
                                            type="text"
                                            v-model="formData.sales_price"
                                            name="sales_price"
                                            id="sales_price"
                                            class="form-control add-credit-card-mask text-end"
                                            placeholder="purchase price"
                                        />
                                        <ErrorMessage name="sales_price" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label">Sales Account</label>
                                        <v-select
                                            v-model="formData.sales_account_head_id"
                                            :options="accountHeads"
                                            label="name"
                                            name="sales_account_head_id"
                                            :reduce="name => name.id"
                                        />
                                    </div>

                                    <div class="col-12">
                                        <label class="form-label" for="cost_of_sales">Cost of sales</label>
                                        <vField
                                            type="text"
                                            v-model="formData.cost_of_sales"
                                            name="cost_of_sales"
                                            id="cost_of_sales"
                                            class="form-control add-credit-card-mask text-end"
                                            placeholder="Cost of sales"
                                        />
                                        <ErrorMessage name="cost_of_sales" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label" for="cost_of_sales_account_head_id">Cost of sales account</label>
                                        <v-select
                                            v-model="formData.cost_of_sales_account_head_id"
                                            :options="accountHeads"
                                            label="name"
                                            name="cost_of_sales_account_head_id"
                                            :reduce="name => name.id"
                                        />
                                    </div>
                                    <div class="col-md-12">
                                        <label class="form-label" for="select2-basic">Inventory account</label>
                                        <v-select
                                            v-model="formData.inventory_account_head_id"
                                            :options="accountHeads"
                                            label="name"
                                            :reduce="name => name.id"
                                        />
                                    </div>

                                    <div class="col-12">
                                        <label class="form-label" for="vat_rate">VAT Rate (%)</label>
                                        <vField
                                            type="text"
                                            v-model="formData.vat_rate"
                                            name="vat_rate"
                                            id="vat_rate"
                                            class="form-control add-credit-card-mask text-end"
                                            placeholder="VAT Rate"
                                        />
                                        <ErrorMessage name="vat_rate" class="invalid-feedback d-block qcont"/>
                                    </div>
                                    <div class="col-12">
                                        <label class="form-label" for="SKU">SKU</label>
                                        <vField
                                            type="text"
                                            v-model="formData.sku"
                                            name="SKU"
                                            id="SKU"
                                            class="form-control add-credit-card-mask"
                                            placeholder="SKU"
                                        />
                                    </div>
                                    <div class="col-md-12 px-2">
                                        <vField type="text" name="inventory_method" class="d-none" v-model="formData.inventory_method" />
                                        <label class="form-label" for="select2-basic">Inventory method</label>
                                        <v-select
                                            v-model="formData.inventory_method"
                                            :options="inventoryMethods"
                                            label="name"
                                            :reduce="name => name.id"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-12">
                                      <label class="form-label" for="supplier">Supplier</label>
                                      <AsyncSelect
                                          placeholder="Supplier Name, ID, Mobile No"
                                          v-model="contactProfile"
                                          :api-service="fetchContactProfiles"
                                          :additional-query="{'type' : 'supplier'}"
                                          :format-label="formatPatientLabel"
                                          :additional-option="additionalOption"
                                          :option-selected="onchangeContact"
                                      />
                                    </div>
                                    <div class="col-12">
                                      <label class="form-label" for="name">Recorder Level</label>
                                      <vField
                                          v-model="formData.recorder_level"
                                          name="recorder_level"
                                          type="text"
                                          id="recorder_level"
                                          class="form-control add-credit-card-mask"
                                          placeholder="Recorder Level"
                                      />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                        
                        <div class="col-12 text-center">
                            <hr class="mt-2">
                            <button :disabled="loading" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                Update
                            </button>
                            <button @click="toggleModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                Cancel
                            </button>
                        </div>
                    </vForm>
                </div>
            </div>
        </div>
        <AlternativeUnitModal
            ref="alternateUnitModal"
            :selected-unit-id="formData.unit_id"
            :unit-list="units"
            :edit-unit="editUnit"
            @unitModalClose="unitModalClose"
        />
        <GlobalLoader />
    </div>
</template>

<script>
import { useDropzone } from 'vue3-dropzone'
import BlobImageViewer from '@/components/atom/BlobImageViewer'
import BrandValue from './BrandValue'
import AlternativeUnitModal from "@/components/molecule/company/inventory/product-service/AlternativeUnitModal.vue";
import handleInventory from "@/services/modules/inventory";
import Loader from "@/components/atom/loader/Loader.vue";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleContact from '@/services/modules/contact'
import { ref } from 'vue';

export default {
    name: 'ProductEditModal',

    components: {
        GlobalLoader,
        Loader,
        AlternativeUnitModal,
        BlobImageViewer,
        BrandValue,
        AsyncSelect
    },

    data: () => ({
        isOpen: false,
        loading: false,
        schema: {
            name: 'required|min:3|max:150',
            product_head_id: 'required',
            unit_id: 'required',
            short_description: 'min:3|max:550',
            full_description: 'min:3|max:1550',
            vat_rate: 'numeric'
        },
        alternativeUnits: [],
        selectedAlternativeUnit: null,
        editUnit: null,
        selectedAlternativeUnits: []
    }),

    computed: {
        specificationArr () {
            return Object.keys(this.specifications)
        },
        alternativeUnitsData () {
            return this.selectedAlternativeUnits.filter(unit => unit.id !== this.formData.unit_id)
        }
    },

    watch: {
        'formData.unit_id'(newValue) {
            this.alternativeUnits = []
            this.selectedAlternativeUnit = null
            this.selectedAlternativeUnits = []
            this.formData.alternative_unit_ids = []
            const unit = this.units.find(unit => unit.id == newValue)
            if(unit) {
                this.selectedAlternativeUnits.push(unit);
            }
            if(newValue) {
                this.getAlternativeUnits()
            }
        },
        'formData.alternative_units'(newValue) {
            if (newValue && Array.isArray(newValue)) {
                this.formData.alternative_unit_ids = newValue.map(unit => {
                    this.selectedAlternativeUnits.push(unit);
                    return unit.id
                })
            }
        },
        'productSingleOriginal.contact_profile'(newValue) {
            if (newValue) {
                this.contactProfile = newValue
            }
        },
    },

    mounted() {
        this.emitter.on('modalOpen', () => {
            this.toggleModal();
        })
    },

    methods: {
        submit() {
            this.$emit('onClickUpdateProduct')
        },
        toggleModal () {
             if(this.isOpen) {
                this.$emit('onCloseModal')
            }
            this.isOpen = !this.isOpen
            this.selectedAlternativeUnit = null;
            this.selectedAlternativeUnits = [];
            this.$emit('toggleModal');
        },
        onDeselectSpec (item) {
           this.removeBrandValue(item)
        },
        removeImage (index) {
            this.formData.additional_images.splice(index, 1)
        },
        onUploadPrimaryPhoto(event) {
            this.formData.image = event.target.files[0]
        },
        toggleAlternativeUnitModal () {
            if(! this.formData.unit_id) {
                this.showError("Please select base unit id first");
                return;
            }
            this.$refs.alternateUnitModal.toggleModal()
        },
        getAlternativeUnits () {
            this.alternativeUnits = [];
            const query = `?company_id=${this.$route.params.companyId}&base_unit_id=${this.formData.unit_id}`
            if(this.formData.unit_id) {
                this.fetchAlternativeUnits(query)
                    .then(res => {
                        if(res.data) {
                            this.alternativeUnits = res.data
                        }
                    })
            }
        },
        selectAlternativeUnit(unit) {
            if(unit.company_id == this.$route.params.companyId) {
                if(!this.formData.alternative_unit_ids.includes(unit.id)) {
                    this.formData.alternative_unit_ids.push(unit.id)
                    this.selectedAlternativeUnits.push(unit)
                    return;
                }
                const index = this.selectedAlternativeUnits.findIndex(u => {
                    return u.id === unit.id;
                });
                this.selectedAlternativeUnits[index] = unit;
            }
        },
        unitModalClose (unit) {
            if(unit) {
                this.getAlternativeUnits();
                this.selectAlternativeUnit(unit);
            }
        },
        deleteSelectedUnit(e, id) {
            e.preventDefault()
            if(this.formData.alternative_unit_ids.includes(id)) {
                this.selectedAlternativeUnits = this.selectedAlternativeUnits.filter(unit => unit.id !== id)
                this.formData.alternative_unit_ids = this.formData.alternative_unit_ids.filter(unitId => unitId !== id)
            }
        },
        triggerEdit(e, id) {
            e.preventDefault()
            if(id) {
                this.loading = true
                this.getUnitById(`${id}?company_id=${this.$route.params.companyId}`).then(res => {
                    this.editUnit = res.data
                    this.$refs.alternateUnitModal.toggleModal();
                })
                .catch(err => {
                    if(err.response.message) {
                        showError(err.response.message)
                    }
                })
                .finally(() => this.loading = false)

            }
        }
    },

    props: {
        formData: Object,
        productSingleOriginal: Object,
        accountHeads: Array,
        clue: Object,
        units: Array,
        group: Array,
        specifications: Object,
        setBrandValue: Function,
        removeBrandValue: Function,
        selectedSpecifications: Array,
        inventoryMethods: Array,
    },

    setup(props) {
        function onDrop(acceptFiles, rejectReasons) {
            props.formData.additional_images = [...props.formData.additional_images, ...acceptFiles]
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop })
        const {getUnitById, fetchAlternativeUnits} = handleInventory()

        const { fetchContactProfiles } = handleContact();
        const { formatPatientLabel } = useAsyncDropdownHelper();
        const contactProfile = ref(null);
        const additionalOption = ref([]);
        
        const onchangeContact = (e) => {
            props.formData.contact_profile_id = e.id;
        }

        return {
            getRootProps,
            getInputProps,
            getUnitById,
            fetchAlternativeUnits,
            ...rest,
            fetchContactProfiles,
            formatPatientLabel,
            contactProfile,
            additionalOption,
            onchangeContact
        }
    }
}
</script>

<style scoped>
.modal-custom{
    max-width: 70vw;
}
</style>
